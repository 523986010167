.aqua{
    
    background-size: cover;
    margin-top: -20px;

}


.text-iom{
    
}